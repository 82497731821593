<template>
  <router-view></router-view>
</template>

<script>
import {
  CLEAR_CLIENT_ERRORS,
  CLEAR_CLIENT_INFO,
  CLEAR_CLIENT_STATS
} from "@/core/services/store/core/client.module";
import { CLEAR_PROJECT_STATS } from "@/core/services/store/project/project.module";
import { ADD_MENU_TYPE } from "@/core/services/store/system/menu.module";

export default {
  name: "Clients",

  mounted() {
    this.clearStore();
    this.setMenuType();
  },

  methods: {
    clearDomain() {
      if(window.location.host.split('.')[0] != "admin") {
        let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
        window.location.href = protocol + process.env.VUE_APP_ADMIN_URL_SUFFIX + "/#/clients/view";
      };
    },
    clearStore() {
      this.$store.dispatch(CLEAR_CLIENT_ERRORS);
      this.$store.dispatch(CLEAR_CLIENT_INFO);
      this.$store.dispatch(CLEAR_CLIENT_STATS);
      this.$store.dispatch(CLEAR_PROJECT_STATS);
    },
    setMenuType() {
      this.$store.dispatch(ADD_MENU_TYPE, "root");
    }
  },

  watch: {
    $route: function() {
      this.clearStore();
    }
  },
};
</script>
